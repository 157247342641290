import React, {useMemo, useState} from 'react';
import {Search, Users} from 'lucide-react';

const CustomerTooltip = ({customers}) => {
  return (
      <div className="max-h-[300px] overflow-y-auto p-2 space-y-1">
        {customers.map((name, idx) => (
            <div key={idx} className="text-sm whitespace-nowrap text-gray-600">
              {name}
            </div>
        ))}
      </div>
  );
};

const TopCustomersChart = ({data = []}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({key: 'net_amount', direction: 'desc'});
  const [selectedBranch, setSelectedBranch] = useState('all');

  // Get unique branches
  const branches = useMemo(() => {
    const branchSet = new Set();
    data.forEach(customer => {
      if (customer.branch_name) {
        customer.branch_name.forEach(branch => branchSet.add(branch));
      }
    });
    return ['all', ...Array.from(branchSet)].sort();
  }, [data]);

  // Process and sort data
  const processedData = useMemo(() => {
    return data
        .map(customer => ({
        ...customer,
          customerNames: Array.isArray(customer.rcu_name)
              ? customer.rcu_name
                  .map(name => name.trim())
                  .filter(name => name && name !== " ")
              : [customer.rcu_name?.trim()].filter(Boolean),
          displayName: Array.isArray(customer.rcu_name)
              ? customer.rcu_name[0]?.trim()
              : customer.rcu_name?.trim(),
          net_amount: Number(customer.net_amount),
          sale_qty: Number(customer.sale_qty),
          bill_count: Number(customer.bill_count),
          avg_bill_value: Number((customer.net_amount / customer.bill_count).toFixed(2)),
          avg_qty_per_bill: Number((customer.sale_qty / customer.bill_count).toFixed(2)),
          brands: Array.from(new Set(customer.brand)).length,
          products: Array.from(new Set(customer.product)).length,
          hasMultipleNames: Array.isArray(customer.rcu_name) &&
              customer.rcu_name.filter(name => name.trim() && name.trim() !== " ").length > 1
        }))
        .filter(customer => {
          const matchesSearch = (
              customer.customerNames.some(name =>
                  name.toLowerCase().includes(searchTerm.toLowerCase())
              ) ||
              customer.rcu_mobile_no?.includes(searchTerm)
          );
          const matchesBranch = selectedBranch === 'all' ||
              (customer.branch_name && customer.branch_name.includes(selectedBranch));
          return matchesSearch && matchesBranch;
        })
        .sort((a, b) => {
          const key = sortConfig.key;
          if (a[key] < b[key]) return sortConfig.direction === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0;
        });
  }, [data, searchTerm, selectedBranch, sortConfig]);

  // Calculate totals
  const totals = useMemo(() => ({
    totalAmount: processedData.reduce((sum, customer) => sum + customer.net_amount, 0),
    totalQuantity: processedData.reduce((sum, customer) => sum + customer.sale_qty, 0),
    totalBills: processedData.reduce((sum, customer) => sum + customer.bill_count, 0),
    uniqueCustomers: new Set(processedData.flatMap(c => c.customerNames)).size
  }), [processedData]);

  if (!data || data.length === 0) {
    return (
        <div className="flex items-center justify-center h-64 text-gray-500">
          No data to show for the given date range
      </div>
    );
  }

  const handleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  const formatCurrency = (value) =>
      `₹${value.toLocaleString(undefined, {maximumFractionDigits: 0})}`;

  return (
      <div className="space-y-4 bg-white rounded-lg p-4">
        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="bg-blue-500/10 p-4 rounded-xl border border-blue-500/20">
            <h3 className="text-sm font-medium text-blue-600">Total Sales</h3>
            <p className="text-lg font-bold text-blue-600">{formatCurrency(totals.totalAmount)}</p>
          </div>
          <div className="bg-green-500/10 p-4 rounded-xl border border-green-500/20">
            <h3 className="text-sm font-medium text-green-600">Total Quantity</h3>
            <p className="text-lg font-bold text-green-600">{totals.totalQuantity.toLocaleString()} units</p>
        </div>
          <div className="bg-purple-500/10 p-4 rounded-xl border border-purple-500/20">
            <h3 className="text-sm font-medium text-purple-600">Total Bills</h3>
            <p className="text-lg font-bold text-purple-600">{totals.totalBills.toLocaleString()}</p>
          </div>
          <div className="bg-orange-500/10 p-4 rounded-xl border border-orange-500/20">
            <h3 className="text-sm font-medium text-orange-600">Unique Customers</h3>
            <p className="text-lg font-bold text-orange-600">{totals.uniqueCustomers.toLocaleString()}</p>
          </div>
        </div>

        {/* Filters */}
        <div className="flex flex-wrap gap-4 items-center">
          <div className="relative flex-1 max-w-sm">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500"/>
            <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search by name or mobile..."
                className="w-full bg-white border border-gray-300 rounded-lg pl-10 pr-4 py-2 text-sm text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50"
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-700">Branch:</span>
            <select
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
                className="bg-white border border-gray-300 text-gray-700 text-sm rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500/50"
          >
              {branches.map(branch => (
                  <option key={branch} value={branch} className="bg-white">
                    {branch === 'all' ? 'All Branches' : branch}
                  </option>
              ))}
            </select>
          </div>
        </div>

        {/* Table */}
        <div className="overflow-x-auto bg-white rounded-lg shadow-sm border border-gray-200">
          <table className="w-full">
            <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-sm font-medium text-gray-600">Customer</th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-600 cursor-pointer"
                  onClick={() => handleSort('net_amount')}>
                Total Amount {sortConfig.key === 'net_amount' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-600 cursor-pointer"
                  onClick={() => handleSort('sale_qty')}>
                Quantity {sortConfig.key === 'sale_qty' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-600 cursor-pointer"
                  onClick={() => handleSort('bill_count')}>
                Bills {sortConfig.key === 'bill_count' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-600 cursor-pointer"
                  onClick={() => handleSort('avg_bill_value')}>
                Avg Bill Value {sortConfig.key === 'avg_bill_value' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-600">Branches</th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-600">Categories</th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-600">Brands</th>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
            {processedData.map((customer, idx) => (
                <tr key={idx} className="group hover:bg-gray-50">
                  <td className="px-4 py-3">
                    <div>
                      <div className="flex items-center gap-2">
                        <div>
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-medium text-gray-900">
                              {customer.displayName}
                            </span>
                            {customer.hasMultipleNames && (
                                <div className="group/tooltip relative">
                                  <button className="p-1 hover:bg-gray-100 rounded transition-colors">
                                    <Users className="w-4 h-4 text-blue-600"/>
                                  </button>
                                  <div
                                      className="absolute left-0 hidden group-hover/tooltip:block z-10"
                                      role="tooltip"
                                  >
                                    <div
                                        className="bg-white border border-gray-200 rounded-lg shadow-lg py-2 px-3"
                                    >
                                      <CustomerTooltip customers={customer.customerNames}/>
                                    </div>
                                  </div>
                                </div>
                            )}
                          </div>

                          <div>
                            {customer.hasMultipleNames && (
                                <span className="text-xs text-blue-600">
                                (...and {customer.customerNames.length - 1} more)
                              </span>
                            )}
                          </div>
                          {customer.rcu_mobile_no && (
                              <div className="text-xs text-gray-600 flex items-center gap-2">
                                {customer.rcu_mobile_no.trim() === "" ? "No number" : customer.rcu_mobile_no.trim()}
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-right text-sm text-blue-600 font-medium">
                    {formatCurrency(customer.net_amount)}
                  </td>
                  <td className="px-4 py-3 text-right text-sm text-green-600">
                    {customer.sale_qty.toLocaleString()}
                  </td>
                  <td className="px-4 py-3 text-right text-sm text-purple-600">
                    {customer.bill_count.toLocaleString()}
                  </td>
                  <td className="px-4 py-3 text-right text-sm text-gray-900">
                    {formatCurrency(customer.avg_bill_value)}
                  </td>
                  <td className="px-4 py-3 text-right text-sm text-gray-900">
                    {customer.branch_name?.join(', ')}
                  </td>
                  <td className="px-4 py-3 text-right text-sm text-gray-900">
                    {customer.products}
                  </td>
                  <td className="px-4 py-3 text-right text-sm text-gray-900">
                    {customer.brands}
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
      </div>
    </div>
  );
};

export default TopCustomersChart;